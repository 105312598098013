function initFileField() {
    var inputs = Array.from(document.querySelectorAll(".form__file input"));

    inputs.forEach((input) => {
        const label = input.nextElementSibling;
        const labelVal = label.dataset.labelValue || "";

        input.addEventListener("change", function (e) {
            let fileName = "";
            if (this.files && this.files.length > 1) {
                fileName = (this.dataset.multipleCaption || "").replace(
                    "{count}",
                    this.files.length
                );
            } else {
                fileName = e.target.value.split("\\").pop();
            }

            if (fileName) {
                label.innerHTML = fileName;
            } else {
                label.innerHTML = labelVal;
            }
        });
    });
}

function initFreeform() {
    const forms = Array.from(document.querySelectorAll(".form[data-id]"));

    forms.forEach((form) => {
        form.addEventListener("freeform-ready", (event) => {
            const freeform = event.target.freeform;

            freeform.addOnSuccessfulAjaxSubmit((evt, form, response) => {
                // Clear file input visually on successful submit.
                const inputs = Array.from(form.querySelectorAll(".form__file input"));

                inputs.forEach(input => {
                    const label =  input.nextElementSibling;
                    const labelVal = label.dataset.labelValue || "";
                    
                    if(label) {
                        label.innerHTML = labelVal;
                    }
                });
            });
        });
    });
}

export default function () {
    initFileField();
    initFreeform();
}
