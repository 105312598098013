import Swiper from "swiper";

import Pagination from "swiper/components/pagination";
import Autoplay from "swiper/components/autoplay";
// configure Swiper to use modules

Swiper.use([Pagination]);
Swiper.use([Autoplay]);

function initHero() {
    const heroSwiperEl = document.querySelector(".hero .swiper-container");

    const heroSwiper = new Swiper(heroSwiperEl, {
        pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
        },
        speed: 500,
        autoplay: {
            delay: 5000,
        },
    });
}

export default function () {
    initHero();
}
