import "core-js/stable";
import "regenerator-runtime/runtime";
import "../sass/site.scss";
import form from "./form";
import header from "./header";
import maps from "./maps";
import swiper from "./swiper";

header();
swiper();
form();
maps();