export default function () {
    const mapEl = document.getElementById("map");

    if (!mapEl) {
        return;
    }

    const token = map.dataset.mapboxToken;
    const lat = map.dataset.mapboxLat;
    const lng = map.dataset.mapboxLng;

    if (!token) {
        console.error("No mapbox token found");
        return;
    }

    if (!lat || !lng) {
        console.error("Latitude or longitude not found");
        return;
    }

    mapboxgl.accessToken = token;

    const mapBox = new mapboxgl.Map({
        container: mapEl,
        style: "mapbox://styles/mapbox/light-v10",
        center: [lng, lat],
        zoom: 12,
    });

    var marker = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(mapBox);

    mapBox.scrollZoom.disable();
    mapBox.addControl(new mapboxgl.NavigationControl(), "bottom-right");
}
